<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <b-card-header class="flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Liste des Produits</h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->

              <!--end::Dropdown-->
              <!--begin::Button-->
              <router-link
                :to="{ name: 'product.new' }"
                class="btn btn-success font-weight-bolder"
              >
                <i class="la la-plus"></i> Ajouter
              </router-link>
              <!--end::Button-->
            </div>
          </b-card-header>
          <b-card-body>
            <!--begin: Datatable-->
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-select
                    style="width: 50px"
                    v-model="perPage"
                    :options="options"
                    size="sm"
                  ></b-form-select>
                </b-col>
                <b-col md="6">
                  <b-form-input
                    class="float-right mb-2"
                    style="width: 200px;"
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Rechercher"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col>
                <b-table
                  bordered
                  hover
                  :items="products"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  responsive="sm"
                >
                  <template v-slot:cell(actions)="data">
                    <router-link
                      class="btn btn-info btn-sm mr-2"
                      :to="{
                        name: 'product.show',
                        params: { id: data.item.id }
                      }"
                    >
                      <i class="fas fa-eye"></i
                    ></router-link>
                    <router-link
                      class="btn btn-warning btn-sm mr-2"
                      :to="{
                        name: 'product.edit',
                        params: { id: data.item.id }
                      }"
                    >
                      <i class="fas fa-edit"></i
                    ></router-link>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="remove(data.item)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :fields="fields"
                  aria-controls="my-table"
                  class="float-right mt-2"
                ></b-pagination>
              </b-col>
            </b-row>
            <!--end: Datatable-->
          </b-card-body>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "Index",
  data() {
    return {
      create: "/new/product",
      source: "product",
      resource: "product",
      products: [],
      total_product: 0,
      total_proposition: 0,
      waiting_proposition: 0,
      // DataTable option
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        {
          key: "titre",
          label: "Titre"
        },
        {
          key: "description",
          label: "Description",
          formatter: value => {
            return value
              .split(" ")
              .splice(0, 3)
              .join("... ");
          }
        },
        {
          key: "qte_stock",
          label: "Qté"
        },
        {
          key: "prix_reel",
          label: "Prix réel",
          formatter: value => {
            return value + " FCFA";
          }
        },
        {
          key: "pourcentage_reduction",
          label: "Réduction",
          formatter: value => {
            return value + " %";
          }
        },
        {
          key: "prix_offre",
          label: "Prix offre",
          formatter: value => {
            return value + " FCFA";
          }
        },
        {
          key: "populaire",
          label: "Pop?",
          formatter: value => {
            if (value === 1) {
              return "Oui";
            }
            return "Non";
          }
        },

        "Actions"
      ],
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ]
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.source).then(function(response) {
        vm.products = response.data.products;
        vm.total_product = response.data.total_product;
        vm.total_proposition = response.data.total_proposition;
        vm.waiting_proposition = response.data.waiting_proposition;
      });
    },
    remove(product) {
      let vm = this;
      Swal.fire({
        title:
          "Vous êtes sûr de vouloir supprimer le produit " +
          product.titre +
          "?",
        text: "Il n'y aura aucun retour en arrière possible !",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(function(isConfirm) {
        if (isConfirm.isConfirmed) {
          ApiService.delete(`${vm.resource}/${product.id}`).then(function(
            response
          ) {
            if (response.data.success) {
              Swal.fire({
                title: "Réussie",
                text: "Produit supprimé avec succès",
                icon: "success",
                confirmButtonText: "Ok"
              });
              vm.fetchData();
            } else {
              Swal.fire({
                title: "Erreur",
                text: "Impossible de supprimer le produit",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            }
          });
        } else {
          Swal.fire({
            title: "Annuler",
            text: "Produit non supprimé :)",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
      });
    }
  },
  computed: {
    rows() {
      return this.products.length;
    }
  }
};
</script>

<style scoped></style>
